import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import GotoDemoHero from 'components/hero/gotoDemo'
import I18nImage from 'components/gallery/i18nImage'
import IconTextItem from 'components/list/iconTextItem'
import SolidDropdown from 'components/dropdown/solid'
import NewsCard from 'components/card/news'

import { Card, CardContent } from '@mui/material'
import MonetizationImage from '/static/images/OTT/monetization.png'
import OTT_OB_Image from '/static/images/OTT/ott_ob_insights.png'
import OTT_DEL_Image from '/static/images/OTT/ott_del_global.png'
import OTT_OP_SCALE from '/static/images/OTT/ott_op_scale_video.png'

import {
  section2aItems,
  section2Items,
  section3Items,
  section4Items,
  section5Items,
  section6Items,
  productAspectList,
  surveyFeaturesList
} from '/static/data/ott.static'
import newsList from '/static/data/newsList.static'

const OTTPage = () => {
  const { t } = useTranslation()
  const { screenM } = useBreakpoint()

  const [productStructureClickIndex, setProductStructureClickIndex] = useState(0)

  const PRODUCT_ASPECT_SELECT = productAspectList.map((item, index) => {
    return {
      name: t(item.title),
      value: index
    }
  })

  const clickProductStructureButton = (index) => {
    setProductStructureClickIndex(index)
  }

  const buttonClassName = 'mb-3 mx-auto btn border-2 border-blue-300'

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('ottSolutionPage.meta.title'),
            metaDescription: t('ottSolutionPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/solution/ott-streaming/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          className="mb-28 flex flex-row gap-5 min-h-[80vh] py-5 lg:py-24"
          style={{
            backgroundImage: `url(https://static.mlytics.com/images/website/ott_hero_background${
              screenM ? '' : '_mobile'
            }.webp)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: screenM ? 'cover' : '100% 40%',
            backgroundPosition: screenM ? 'center center' : 'top center'
          }}
        >
          <div className={`container grid grid-cols-1 lg:grid-cols-2 ${screenM ? 'items-center justify-center' : ''}`}>
            <div
              className="col-span-1"
              style={
                screenM
                  ? {}
                  : {
                    position: 'relative',
                    top: 'calc(40% + 20px)'
                  }
              }
            >
              <h2
                className={`text-left  text-3xl md:text-3.5xl lg:text-5xl ${screenM ? 'text-white' : 'text-gray-800'}`}
              >
                {t('ottSolutionPage.section1.title')}
              </h2>
              <p className={`py-10 ${screenM ? 'text-white' : 'text-gray-800'}`}>
                {t('ottSolutionPage.section1.description')}
              </p>
              <div className="flex flex-col lg:flex-row gap-3">
                <Link
                  id="ott_banner_01_try"
                  to="/signup/"
                  className="btn btn-bold btn-orange inline-block"
                  style={{ minWidth: 208 }}
                >
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  id="ott_banner_02_domo"
                  to="/lp/get-a-demo/"
                  className="btn border-2 btn-orange bg-white btn-ghost inline-block"
                  style={{ minWidth: 208 }}
                >
                  {t('button.getDemo.0')}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="my-28 px-4  md:px-10">
          <div className="container flex flex-col md:flex-row pt-20">
            <div className="flex-1 py-8 md:pt-0 px-0 md:px-8">
              <I18nImage
                src={MonetizationImage}
                alt={t('ottSolutionPage.section2a.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
            <div className="flex-1 md:px-6">
              <div className="font-black text-lg text-primary-500">{t('ottSolutionPage.section2a.subtitle')}</div>
              <h2 className="font-bold text-3xl md:text-3.5xl">
                <Trans i18nKey="ottSolutionPage.section2a.title">
                  <div></div>
                </Trans>
              </h2>
              <IconTextItem items={section2aItems} />
            </div>
          </div>
        </section>
        <section className="my-28 px-4 md:px-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex-1 md:px-6">
              <div className="font-black text-lg text-primary-500">{t('ottSolutionPage.section2.subtitle')}</div>
              <h2 className="font-bold text-3xl md:text-3.5xl">
                <Trans i18nKey="ottSolutionPage.section2.title">
                  <div></div>
                </Trans>
              </h2>
              <IconTextItem items={section2Items} />
            </div>
            <div className="flex-1 py-8 md:pt-0 px-0 md:px-8">
              <I18nImage
                src={OTT_OB_Image}
                alt={t('ottSolutionPage.section2.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
          </div>
        </section>
        <section className="my-28 px-4 md:px-10">
          <div className="container flex flex-col md:flex-row">
            <div className="flex-1 py-8 md:pt-0 px-0 md:px-8">
              <I18nImage
                src="https://static.mlytics.com/images/website/ott_ob_define_viewers.png"
                alt={t('ottSolutionPage.section3.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
            <div className="flex-1 md:px-6">
              <div className="font-black text-lg text-primary-500">{t('ottSolutionPage.section3.subtitle')}</div>
              <h2 className="font-bold text-3xl md:text-3.5xl">{t('ottSolutionPage.section3.title')}</h2>
              <IconTextItem items={section3Items} />
            </div>
          </div>
        </section>
        <section className="my-28 px-4 md:px-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex-1 md:px-6">
              <div className="font-black text-lg text-primary-500">{t('ottSolutionPage.section4.subtitle')}</div>
              <h2 className="font-bold text-3xl md:text-3.5xl">{t('ottSolutionPage.section4.title')}</h2>
              <IconTextItem items={section4Items} />
            </div>
            <div className="flex-1 py-8 md:pt-0 px-0 md:px-8">
              <I18nImage
                src={OTT_OP_SCALE}
                alt={t('ottSolutionPage.section4.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
          </div>
        </section>
        <section className="my-28 px-4 md:px-10">
          <div className="container flex flex-col md:flex-row">
            <div className="flex-1 py-8 md:pt-0 px-0 md:px-8">
              <I18nImage
                src={OTT_DEL_Image}
                alt={t('ottSolutionPage.section5.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
            <div className="flex-1 md:px-6 ">
              <div className="font-black text-lg text-primary-500">{t('ottSolutionPage.section5.subtitle')}</div>
              <h2 className="font-bold text-3xl md:text-3.5xl">{t('ottSolutionPage.section5.title')}</h2>
              <IconTextItem items={section5Items} />
            </div>
          </div>
        </section>
        <section className="my-28 px-4 md:px-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex-1 md:px-6">
              <div className="font-black text-lg text-primary-500">{t('ottSolutionPage.section6.subtitle')}</div>
              <h2 className="font-bold text-3xl md:text-3.5xl">{t('ottSolutionPage.section6.title')}</h2>
              <IconTextItem items={section6Items} />
            </div>
            <div className="flex-1 py-8 md:pt-0 px-0 md:px-8">
              <I18nImage
                src="https://static.mlytics.com/images/website/ott_prot_content.png"
                alt={t('ottSolutionPage.section6.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
          </div>
        </section>
        <section className="py-16 text-center bg-blue-200 bg-opacity-5">
          <div className="container text-center">
            <h2 className="mb-6 text-primary-500 text-3xl md:text-4.5xl">{t('index.section4.title')}</h2>
            <p className="mb-16 text-gray-600">{t('index.section4.subtitle')}</p>
            <div className="container grid grid-cols-1 lg:grid-cols-5 gap-5">
              <div className="my-5 flex-col col-span-2 hidden md:flex">
                {productAspectList.map((item, index) => (
                  <button
                    id={item.clickID}
                    key={index}
                    className={
                      index === productStructureClickIndex
                        ? `${buttonClassName} bg-blue-300 text-white`
                        : `${buttonClassName} text-blue-300 `
                    }
                    onClick={() => clickProductStructureButton(index)}
                    style={{ height: 'auto', width: 320 }}
                  >
                    {t(item.title)}
                  </button>
                ))}
              </div>
              <div className="mb-5 flex md:hidden col-span-3">
                <SolidDropdown
                  currentOption={productStructureClickIndex}
                  options={PRODUCT_ASPECT_SELECT}
                  changeMenuState={(event, index) => clickProductStructureButton(index)}
                />
              </div>

              <div className="col-span-3">
                <Card
                  className="rounded-lg"
                  sx={{
                    '&.MuiPaper-root': {
                      boxShadow: 'none',
                      overflow: 'visible',
                      borderRadius: '32px'
                    }
                  }}
                >
                  <div className="relative md:h-[20rem] h-[11rem] w-[105%]">
                    {productAspectList.map((item, index) => (
                      <I18nImage
                        key={index}
                        className={`absolute bottom-0 left-0 transition-opacity duration-1000 ease-in-out md:h-[22rem] h-[13rem] w-[105%] ${
                          productStructureClickIndex === index
                            ? 'opacity-100 scale-100 h-auto'
                            : 'opacity-0 scale-0 h-0'
                        }`}
                        src={item.imageSrc}
                        alt={t(item.imageAlt)}
                        style={{
                          width: '110%',
                          height: 'auto'
                        }}
                      />
                    ))}
                  </div>

                  <CardContent className="rounded-b-[32px] bg-blue-50 bg-opacity-30">
                    {productAspectList.map((item, index) => (
                      <p
                        key={index}
                        className={`text-gray-700 transition-opacity duration-1000 ease-in-out ${
                          productStructureClickIndex === index
                            ? 'opacity-100 scale-100 h-auto'
                            : 'opacity-0 scale-0 h-0'
                        }`}
                      >
                        {t(item.description)}
                      </p>
                    ))}
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </section>
        <section className="py-16 text-center">
          <h2 className="mb-10 text-primary-500 text-3xl md:text-4.5xl">{t('index.section6.title')}</h2>
          <div className="container px-5 md:px-0 grid grid-cols-1 lg:grid-cols-3 gap-12">
            {surveyFeaturesList.map((item, index) => (
              <div key={index} className="flex flex-col justify-between">
                <div className="flex-1 flex flex-col justify-around">
                  <h3 className="mb-3 text-gray-800">{t(item.title)}</h3>
                  <img
                    className="mb-3"
                    src={item.imageSrc}
                    alt={t(item.imageAlt)}
                    style={{ height: 'auto', width: '100%' }}
                  />
                </div>
                <div>
                  <p className="mb-3 text-center text-gray-800">
                    <Trans i18nKey={item.description}>
                      {item.descriptionLink.includes('https') ? (
                        <a rel="noreferrer" href={item.descriptionLink} className="underline" target="_blank"></a>
                      ) : (
                        <Link to={item.descriptionLink} className="underline " />
                      )}
                    </Trans>
                  </p>
                  {item.isOutboundLink ? (
                    <a
                      id={item.clickID}
                      href={item.href}
                      className="btn btn-blue mx-auto"
                      style={{ height: 'auto', minWidth: 320 }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(item.linkText)}
                    </a>
                  ) : (
                    <Link
                      id={item.clickID}
                      to={item.href}
                      className="btn btn-blue mx-auto"
                      style={{ height: 'auto', minWidth: 320 }}
                    >
                      {t(item.linkText)}
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>

        <GotoDemoHero title="ottSolutionPage.section9.title" clickIds={['ott_sup_01_try', 'ott_sup_02_demo']} />

        <section className="hidden xl:block px-10 py-24">
          <h2 className="font-black text-center text-4.5xl text-primary-600">{t('heading.productPlatform')}</h2>
          <div className="flex justify-center mt-16">
            <img
              src="https://static.mlytics.com/images/website/mlytics_platform.svg"
              alt={t('imageAlt.platform')}
              style={{ height: 'auto', maxWidth: '1260px' }}
            />
          </div>
        </section>
        <section className="p-10">
          <h2 className="text-center text-primary-600 text-6xl mt-10">{t('heading.latestNews')}</h2>
          <div className="flex flex-col xl:flex-row items-center justify-center mt-10 mb-20">
            {newsList.map((item, index) => (
              <NewsCard
                key={index}
                className="w-1/4 mx-2 mb-3"
                flag={t(item.flag)}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                paragraph={t(item.paragraph)}
                anchorHref={item.anchorHref}
                imageSrc={item.imageSrc}
                imageAlt={t(item.imageAlt)}
              />
            ))}
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default OTTPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
