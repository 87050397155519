/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { List, ListItem, Divider, ListItemText, ListItemAvatar } from '@mui/material'
import Icon from 'components/icon'

const typeDataMappingList = {
  challenge: {
    className: {
      title: 'text-gray-800',
      description: 'text-gray-600',
      icon: 'text-white',
      iconBackground: 'bg-gray-800'
    },
    iconName: 'new_releases_outlined'
  },
  solutions: {
    className: {
      title: 'text-blue-200',
      description: 'text-blue-200',
      icon: 'text-white',
      iconBackground: 'bg-blue-100 '
    },
    iconName: 'tips_and_updates_outlined'
  }
}

const CustomTransComponents = ({ links = [] }) => ({
  href: (
    <React.Fragment>
      {links?.map((link, linkIndex) =>
        link.includes('https') ? (
          <a key={linkIndex} className="underline cursor-pointer" target="_blank" href={link} rel="noreferrer"></a>
        ) : (
          <Link key={linkIndex} className="underline cursor-pointer" to={link} />
        )
      )}
    </React.Fragment>
  )
})

const IconTextItem = ({ items = [] }) => {
  const { t } = useTranslation()

  return (
    <List>
      {items.map(({ type, title, description, links = [] }, index) =>
        type === 'solutions' ? (
          <SolutionComponent
            key={type}
            index={index}
            type={type}
            title={title}
            description={description}
            links={links}
          />
        ) : (
          <div key={type}>
            <ListItem
              alignItems="flex-start"
              sx={{
                padding: '12px 0px'
              }}
            >
              <ListItemAvatar>
                <div
                  className={`flex items-center justify-center pa-1 w-[40px] h-[40px] ${typeDataMappingList[type].className.iconBackground}`}
                >
                  <Icon
                    fontSize="32px"
                    name={typeDataMappingList[type].iconName}
                    className={typeDataMappingList[type].className.icon}
                  />
                </div>
              </ListItemAvatar>
              <ListItemText>
                <h3 className={`text-xl font ${typeDataMappingList[type].className.title}`}>{t(title)}</h3>
                <p className={typeDataMappingList[type].className.description}>
                  <Trans i18nKey={description} components={CustomTransComponents({ links })} />
                </p>
              </ListItemText>
            </ListItem>
          </div>
        )
      )}
    </List>
  )
}

IconTextItem.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      links: PropTypes.array
    })
  )
}

export default IconTextItem

function SolutionComponent({ type, title, description, links, index }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  function handleOpen() {
    setOpen(!open)
  }

  return (
    <div key={type} className="w-full ">
      {index % 2 === 1 && (
        <Divider
          style={{ minWidth: '100%' }}
          className={` transition-all ease-out duration-300 ${
            !open ? 'opacity-0 max-h-0' : 'opacity-100 max-h-full'
          } overflow-hidden`}
        />
      )}
      <ListItem
        // style={{ minHeight: '100%' }}
        alignItems="flex-start"
        className={`transition-all  h-100 ease-out duration-300 overflow-hidden   ${
          !open ? 'opacity-0 max-h-0' : 'opacity-100 max-h-full'
        } `}
        sx={{
          padding: !open ? '3px 0px' : '12px 0px'
        }}
      >
        <ListItemAvatar>
          <div
            className={`flex items-center justify-center w-[40px] h-[40px] ${typeDataMappingList[type].className.iconBackground}`}
          >
            <Icon
              fontSize="32px"
              name={typeDataMappingList[type].iconName}
              className={typeDataMappingList[type].className.icon}
            />
          </div>
        </ListItemAvatar>
        <ListItemText className={'m-0'}>
          <h3 className={`text-xl font ${typeDataMappingList[type].className.title}`}>{t(title)}</h3>
          <p className={typeDataMappingList[type].className.description}>
            <Trans i18nKey={description} components={CustomTransComponents({ links })} />
          </p>
        </ListItemText>
      </ListItem>
      <button
        onClick={handleOpen}
        style={{ minWidth: 208 }}
        className={`transition-all ease-out duration-300  ${
          open ? 'opacity-0 h-0 py-0 m-0 invisible' : 'opacity-100 max-h-full py-4'
        } overflow-hidden btn w-full md:w-3 text-blue-200 border-blue-200 btn-bold bg-white inline-block hover:opacity-100`}
      >
        {t('iconTextItemSloveBtn')}
      </button>
    </div>
  )
}
