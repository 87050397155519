const section2aItems = [
  {
    type: 'challenge',
    title: 'iconTextItemType.0',
    description: 'ottSolutionPage.section2a.items.0'
  },
  {
    type: 'solutions',
    title: 'iconTextItemType.1',
    description: 'ottSolutionPage.section2a.items.1',
    links: ['https://www.mlytics.com/blog/the-keystone-of-video-content-monetization/']
  }
]

const section2Items = [
  {
    type: 'challenge',
    title: 'iconTextItemType.0',
    description: 'ottSolutionPage.section2.items.0'
  },
  {
    type: 'solutions',
    title: 'iconTextItemType.1',
    description: 'ottSolutionPage.section2.items.1',
    links: [
      'https://www.mlytics.com/blog/maximize-your-ott-platforms-potential-with-vital-streaming-metrics-and-kpis-understand-the-key-analytics-and-enhance-the-streaming-experience-and-results/?highlight=GROW'
    ]
  }
]

const section3Items = [
  {
    type: 'challenge',
    title: 'iconTextItemType.0',
    description: 'ottSolutionPage.section3.items.0'
  },
  {
    type: 'solutions',
    title: 'iconTextItemType.1',
    description: 'ottSolutionPage.section3.items.1',
    links: ['https://www.mlytics.com/blog/improve-viewer-retention-qoe/']
  }
]

const section4Items = [
  {
    type: 'challenge',
    title: 'iconTextItemType.0',
    description: 'ottSolutionPage.section4.items.0'
  },
  {
    type: 'solutions',
    title: 'iconTextItemType.1',
    description: 'ottSolutionPage.section4.items.1',
    links: [
      '/video-stream/instream-switching/',
      'https://www.mlytics.com/blog/why-your-website-speed-and-performance-are-costing-you-business/'
    ]
  }
]

const section5Items = [
  {
    type: 'challenge',
    title: 'iconTextItemType.0',
    description: 'ottSolutionPage.section5.items.0'
  },
  {
    type: 'solutions',
    title: 'iconTextItemType.1',
    description: 'ottSolutionPage.section5.items.1',
    links: [
      '/video-stream/multi-cdn-management/',
      'https://www.mlytics.com/blog/local-cdn-solutions-for-overcoming-chinas-great-firewall-icp/'
    ]
  }
]

const section6Items = [
  {
    type: 'challenge',
    title: 'iconTextItemType.0',
    description: 'ottSolutionPage.section6.items.0'
  },
  {
    type: 'solutions',
    title: 'iconTextItemType.1',
    description: 'ottSolutionPage.section6.items.1'
  }
]

const productAspectList = [
  {
    clickID: 'ott_integration_01_LCD',
    title: 'index.productAspectList.stream.0.title',
    description: 'index.productAspectList.stream.0.description',
    imageSrc: 'https://static.mlytics.com/images/website/stream_lcd.png',
    imageAlt: 'index.productAspectList.stream.0.title'
  },
  {
    clickID: 'ott_integration_02_inte',
    title: 'index.productAspectList.stream.1.title',
    description: 'index.productAspectList.stream.1.description',
    imageSrc: 'https://static.mlytics.com/images/website/stream_integration.png',
    imageAlt: 'index.productAspectList.stream.1.title'
  },
  {
    clickID: 'ott_integration_03_smart',
    title: 'index.productAspectList.stream.2.title',
    description: 'index.productAspectList.stream.2.description',
    imageSrc: 'https://static.mlytics.com/images/website/stream_smart_adapt.png',
    imageAlt: 'index.productAspectList.stream.2.title'
  },
  {
    clickID: 'ott_integration_04_analytics',
    title: 'index.productAspectList.stream.3.title',
    description: 'index.productAspectList.stream.3.description',
    imageSrc: 'https://static.mlytics.com/images/website/stream_analytics.png',
    imageAlt: 'index.productAspectList.stream.3.title'
  }
]

const surveyFeaturesList = [
  {
    title: 'index.surveyFeaturesList.0.title',
    description: 'index.surveyFeaturesList.0.description',
    descriptionLink: 'https://www.mlytics.com/blog/ultimate-video-streaming-solution',
    imageSrc: 'https://static.mlytics.com/images/website/monitoring_hubs.png',
    imageAlt: 'index.surveyFeaturesList.0.title',
    href: 'https://hubble.mlytics.com/basic_test',
    linkText: 'index.surveyFeaturesList.0.linkText',
    clickID: 'ott_sup_03_test',
    isOutboundLink: true
  },
  {
    title: 'index.surveyFeaturesList.1.title',
    description: 'index.surveyFeaturesList.1.description',
    descriptionLink: '/features/china-access/',
    imageSrc: 'https://static.mlytics.com/images/website/cost_effective_cdn_marketplace.png',
    imageAlt: 'index.surveyFeaturesList.1.title',
    href: '/features/power-ups/',
    linkText: 'index.surveyFeaturesList.1.linkText',
    clickID: 'ott_sup_04_shop',
    isOutboundLink: false
  },
  {
    title: 'index.surveyFeaturesList.2.title',
    description: 'index.surveyFeaturesList.2.description',
    descriptionLink: '',
    imageSrc: 'https://static.mlytics.com/images/website/soc_support.png',
    imageAlt: 'index.surveyFeaturesList.2.title',
    href: '/features/enterprise-support/',
    linkText: 'index.surveyFeaturesList.2.linkText',
    clickID: 'ott_sup_05_247',
    isOutboundLink: false
  }
]

export {
  section2aItems,
  section2Items,
  section3Items,
  section4Items,
  section5Items,
  section6Items,
  productAspectList,
  surveyFeaturesList
}
